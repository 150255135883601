import React from 'react'

function Footer() {
  return (
   <>
                <div class="bg-black pb-3 mx-auto">
                    <p class="  text-md text-gray-400 font-medium text-center">
                        © 2024 All rights reserved.
                    </p>
                </div>
   </>
  )
}

export default Footer